.team-member-title {
  color: grey;
}

.team-member-button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: darkblue;
  text-align: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1vh;
  border-radius: 12px;
}

.team-member-button:hover {
  background-color: blue;
}

.team-member-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.team-page {
  background-color: #282c34;
  overflow: hidden;
  text-align: left;
  margin: auto;
  align-items: stretch;
  width: 100%;
  color: white;
}

.member-spacing div {
  margin-left: auto;
  margin-right: auto;
}
.teamPage {
  text-align: justify;
}

.social-container {
  background: #eee;
  padding: 25px 50px;
}

