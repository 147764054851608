@media (max-width: 600px) {
  .carousel {
    width: auto;
    height: auto;
  }
}

.carousel-inner {
  border-radius: 12px;
}
.carousel-inner:hover .carousel-caption {
  opacity: 0;
}

.carousel-inner .carousel-caption {
  left: 10%;
  top: 50%;
  right: 10%;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.4s;
}

.carousel-dark .carousel-caption {
  color: #282c34 !important;
  font-family: "Calibri";
}

.homeCarousel {
  width: auto;
  max-width: 60vw;
  height: auto;
  max-height: 40vw;
  text-align: center;
  margin-bottom: 8vw;
}

.homePage {
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: justify;
  color: white;
}

.homePage-vertical-streamer-img {
  margin-bottom: 2vh;
  width: auto;
  height: auto;
}

.homePage-span {
  margin-top: 5vh;
}
