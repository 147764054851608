.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #c9e5f5;
}

.CardArray {
  width: 80vw;
  margin: 5vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* -------------------------------------------------------------------*/
/* Footer management color and position, check up Footer.jsx dans footerComponents*/
.footer {
  position: relative;
  background-color: #c9e5f5;
  color: black;
}

.footer-hr {
  position: relative;
  color: #282c34;
  width: 90%;
  margin: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.footer-icon-img {
  max-width: 100%;
  max-height: 10vh;

  width: auto;
  transition: all 0.2s ease-in-out;
}

.footer-icon-img:hover {
  transform: scale(1.1);
}

.footer h5 {
  text-align: center;
}

/* Nav -------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
  .nav-streamers {
    background-color: #b6e4ff;
    max-width: 100%;
    display: flex;
    color: black;
    height: 210px;
  }
}

.nav-streamers {
  background-color: #b6e4ff;
  max-width: 100%;
  display: flex;
  color: black; 
}
  .nav-streamers-img {
    max-height: 100%;
    max-width: 100%;
    height: 60%;
    object-fit: cover;
  }
  
  .nav-streamers-main-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    padding-bottom: 0;
  }
  .nav-streamers-main-col {
    max-width: 100%;
  }
  
  .nav-streamers-col {
    max-width: 100%;
    max-height: 100%;
    position: flex;
  }
  /* Check if it's coming from the title or not really"*/
  .nav-streamers-row {
    width: 100%;
    margin: auto;
    padding-top: 3%;
    max-height: 100%;
    max-width: 100%;
  }

.my-nav {
  margin: auto;
}

.hidden-hyperlink {
  transition: transform 250ms;
  color: white;
  text-decoration: none;
}

.hidden-hyperlink:hover {
  text-decoration: none;
  color: whitesmoke;
  transform: translateY(-2px);
}

.hidden-hyperlink:visited {
  color: inherit;
  text-decoration: none;
}

/*------------------------------------------------------*/
.top-span {
  margin-top: 5vh;
}

.thick-hr {
  height: 1vh !important;
}

.medium-hr {
  height: 1vh !important;
}

.custom-accordion {
  color: white;
  background-color: #282c34;
}

.offcanvas-header {
  background-color: #b6e4ff;
}

.offcanvas-title {
  font-weight: bold !important;
}

.offcanvas-body {
  background-color: #b6e4ff;
  text-align: justify;
}
/*----------------------------------------*/

.accordion-test {
  border: solid red;
}

.news-accordion-header {
  background-color: #b6e4ff !important;
  color: white;
}

.news-accordion-body {
  background-color: linear-gradient(#b6e4ff, #97f08a);
  color: black;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(#89caf0, #97f08a);
  flex-direction: row;
}
.news-card {
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(#89caf0, #97f08a);
  flex-direction: row;
}
