.publication-page {
  background-color: #282c34;
  overflow: hidden;
  position: relative;
  width: 100%;
  color: black;
}

.publication-card {
  width: auto;
  max-width: 80%;
  height: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(#89caf0, #97f08a);
}

.publication-card:hover {
  transform: scale(1.1);
}

.publication-hyperlink {
  color: inherit;
  text-decoration: inherit;
}

.publication-hyperlink:hover {
  color: inherit;
  text-decoration: none;
}

.publication-hyperlink:visited {
  color: inherit;
  text-decoration: none;
}

.card-body {
  display: flex;
}
.c-20 {
  width: 20%;
  padding: 0;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /*border: solid;   */
}

.c-80 {
  /*border: solid;*/
  padding: 0;
  margin: 0;
  width: 80%;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  height: 100%;
}

