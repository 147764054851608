.theoryPage {
  margin: auto;
  /*border: 3px solid green;*/
  padding-top: 2vh;
  margin-top: 2vh;
  text-align: justify;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.theoryPage-col {
  max-width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: auto;
  margin-right: auto;
}

.theoryPage-row {
  max-width: 100%;
  margin: auto;
}

.theoryPage-card {
  width: 100%;
  /* border: 3px solid blue; */
  transition: ease-in-out 0.5s;
}

.theoryPage-card .theoryPage-card-img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.theoryPage-card-title {
  color: black;
}

.theoryPage-card-title p {
  color: black;
}

.card-text {
  color: black;
  opacity: 0;
}

.theoryPage-summary {
  margin-bottom: 5vh;
  margin: auto;
  text-align: justify;
}

/* --------------------------------------------------------------------*/
.theoryPage-card {
  position: relative;
}

.theoryPage-card:before {
  content: "";
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  top: calc(4px / -1);
  left: calc(4px / -1);
  background: linear-gradient(to right, #89caf0 0%, #89caf0 100%),
    linear-gradient(to top, #89caf0 50%, transparent 50%),
    linear-gradient(to top, #89caf0 50%, transparent 50%),
    linear-gradient(to right, #89caf0 0%, #89caf0 100%),
    linear-gradient(to left, #89caf0 0%, #89caf0 100%);
  background-size: 100% 4px, 4px 200%, 4px 200%, 0% 4px, 0% 4px;
  background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
  background-repeat: no-repeat, no-repeat;
  transition: transform 0.2s ease-in-out, background-position 0.2s ease-in-out,
    background-size 0.2s ease-in-out;
  transform: scaleX(0) rotate(180deg);
  transition-delay: 0.4s, 0.2s, 0s;
}
.theoryPage-card:hover:before {
  background-size: 200% 4px, 4px 400%, 4px 400%, 55% 4px, 55% 4px;
  background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
  transform: scaleX(1) rotate(180deg);
  transition-delay: 0s, 0.2s, 0.4s;
}
/* --------------------------------------------------------------------*/
.theoryPage-IV-img {
  max-width: 100%;
  height: 90vh !important;
  margin: auto;
  text-align: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: white;
}

.theoryPage-IV-img2 {
  max-width: 100%;
  height: 50vh !important;
  margin: auto;
}
/* --------------------------------------------------------------------*/


.theoryPage-I-2Tier {
  max-width: 100%;
  width: 66% !important;
  margin: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: white;
}

.theoryPage-I-1Tier {
  max-width: 100%;
  width: 33% !important;
  margin: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: white;
}

.vertical-streamer-img {
  margin-top: 2vh;
}
