@media screen and (max-width: 320px) {
  .text-small {
    font-size: 7px;
  }
  .text-normal {
    font-size: 10px;
  }

  .text-subtitle {
    font-size: 12px;
  }

  .text-title {
    font-size: 15px;
  }

  .text-big-title {
    font-size: 20px;
  }
}
/* ----------------------------------------------------------------------------------------*/
@media screen and (min-width: 480px) {
  .text-small {
    font-size: 9px;
  }
  .text-normal {
    font-size: 12px;
  }

  .text-subtitle {
    font-size: 14px;
  }

  .text-title {
    font-size: 16px;
  }

  .text-big-title {
    font-size: 20px;
  }


}
/* ----------------------------------------------------------------------------------------*/
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (min-width: 600px) {
  .text-small {
    font-size: 9px;
  }
  .text-normal {
    font-size: 12px;
  }

  .text-subtitle {
    font-size: 15px;
  }

  .text-title {
    font-size: 17px;
  }

  .text-big-title {
    font-size: 22px;
  }


}
/* ----------------------------------------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .text-small {
    font-size: 11px;
  }
  .text-normal {
    font-size: 15px;
  }

  .text-subtitle {
    font-size: 17px;
  }

  .text-title {
    font-size: 19px;
  }

  .text-big-title {
    font-size: 24px;
  }
}
/* ----------------------------------------------------------------------------------------*/
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (min-width: 900px) {
  .text-small {
    font-size: 14px;
  }
  .text-normal {
    font-size: 18px;
  }

  .text-subtitle {
    font-size: 20px;
  }

  .text-title {
    font-size: 22px;
  }

  .text-big-title {
    font-size: 27px;
  }

}
/* ----------------------------------------------------------------------------------------*/
@media screen and (min-width: 1024px) {
  .text-small {
    font-size: 14px;
  }
  .text-normal {
    font-size: 18px;
  }

  .text-subtitle {
    font-size: 20px;
  }

  .text-title {
    font-size: 23px;
  }

  .text-big-title {
    font-size: 27px;
  }

}
/* ----------------------------------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
  .text-small {
    font-size: 16px;
  }
  .text-normal {
    font-size: 20px;
  }

  .text-subtitle {
    font-size: 22px;
  }

  .text-title {
    font-size: 25px;
  }

  .text-big-title {
    font-size: 32px;
  }

  .publication-page h2 {
    color: white;
    margin-top: 2vh;
    font-family: Impact, fantasy;
  }

}
/* ----------------------------------------------------------------------------------------*/


.animationPage-text {
  width: 80%;
  text-align: justify;
  margin-bottom: 3vh;
  margin-top: 1vh;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.33vw;
}
.homePage-text {
  font-size: 1.33vw;
}
.publication-page h2 {
  color: white;
  margin-top: 2vh;
  font-family: Impact, fantasy;
}
/* ------------------Color and spans(weights/syles)*/
.red-text-span {
  color: red;
  font-weight: bolder;
}

.red {
  color: red;
}
.white {
  color: white;
}

.underline {
  text-decoration: underline;
}

.left {
  text-align: justify;
}

.centered {
  text-align: center;
  margin: auto;
}

.bold {
  font-weight: bold;
}
